<div mat-dialog-content>
  <h2>{{ data.title | translate: data.translationParameters}}</h2>
  <p *ngIf="data.message && !data.isHtml">{{ data.message  | translate: data.translationParameters}}</p>

  <div *ngIf="data.message && data.isHtml" class="htmlContent" [innerHtml]="data.message"></div>

  <div>
    <!-- Fetch selected filed on change -->
    <button mat-raised-button color="primary" (click)="fileInput.click()">
      {{ 'dialog.selectFile' | translate }}
    </button>
    <mat-label>{{ file ? file.name : ('dialog.file' | translate) }}</mat-label>
    <input hidden type="file" #fileInput (change)="onFileInput(fileInput.files)" accept=".csv, text/csv" />
  </div>
</div>

<div class="buttons" mat-dialog-actions>
  <button mat-button color="primary" (click)="dialogRef.close(fileInput.files[0])">{{ 'dialog.uploadFile' | translate }}</button>
  <button mat-button (click)="dialogRef.close()">{{ 'dialog.cancel'| translate }}</button>
</div>
