import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Feature, FeatureCollection, Geometry } from 'geojson';
import { GeoJSONSource, LngLat, LngLatBounds, LngLatBoundsLike, Map, Marker, Popup } from 'mapbox-gl';
import { empty, Observable, zip } from 'rxjs';
import { map } from 'rxjs/operators';
import { CompanyAPIService } from '../../../modules/company/company.api.service';
import { AuthenticationGuard } from '../../helper/guards/authentication.guard';
import { Booking } from '../../models/booking';
import { Rights, Waypoint } from '../../models/index';
import { MobilityOption } from '../../models/mobilityOption';
import { MobiPlaRoute } from '../../models/mobipla/MobiPlaRoute.1';
import { DynamicMapComponent } from './dynamic-map/dynamic-map.component';
import { OpenrouteMatrixCoordinates, OpenRouteMatrixResponse } from '../../models/openRouteServiceResponseModel';
import { OpenrouteCoordinates, MapAPIService } from './map.api.service';

export class LngLatPopup {
  constructor(lngLat: LngLat, name: string) {
    this.lngLat = lngLat;
    this.name = name;
  }
  lngLat: LngLat;
  name: string;
}

@Injectable()
export class MapService {
  segmentColors: { [key: string]: string } = {
    'walk': '#4DB975',
    'foot': '#4DB975',
    'bicylce': '#18CB5B',
    'car': '#6A55AF',
    'towncar': '#4F2BC4',
    'rideshare': '#907FC4',
    'taxi': '#FEE56B',
    'bus': '#F86F52',
    'coach': '#FF8D75',
    'tram': '#FFAC9A',
    'subway': '#FFD19B',
    'train': '#F8AC52',
    'plane': '#5189A7',
  };

  constructor(
    private _companyAPI: CompanyAPIService,
    private _apiService: MapAPIService,
    private _authGuardService: AuthenticationGuard,
    private _domSanitizer: DomSanitizer,
  ) { }

  toLngLatPopupArray(booking: Booking): LngLatPopup[] {
    const lngLatPop: LngLatPopup[] = [];
    // startSite
    lngLatPop.push(new LngLatPopup(
      new LngLat(
        booking.startSite.geoLongitude,
        booking.startSite.geoLatitude),
      booking.startSite.name)
    );

    booking.waypoints.forEach((waypoint, index) => {
      if (waypoint.geoLongitude && waypoint.geoLatitude) {
        lngLatPop.push(new LngLatPopup(new LngLat(waypoint.geoLongitude, waypoint.geoLatitude), waypoint.name));
      }
    });

    // endSite
    lngLatPop.push(new LngLatPopup(
      new LngLat(
        booking.startSite.geoLongitude,
        booking.startSite.geoLatitude),
      booking.startSite.name)
    );

    return lngLatPop;
  }

  getGeocoding(search: string): Observable<Waypoint[]> {
    let userFavoriteWayPoints: Observable<Waypoint[]> = null;
    if (this._authGuardService.hasRight([Rights.view_user_favorite_waypoint])) {
      userFavoriteWayPoints = this._apiService.getUserFavoriteWayPoints(search).pipe(
        map(waypoint => waypoint.map(value => {
          value.userWaypoint = true;
          return value;
        })),
      );
    }

    const geoAddressData = this._companyAPI.getAllGeoAdressesForQuery(search);
    if (userFavoriteWayPoints) {
      return zip(userFavoriteWayPoints, geoAddressData).pipe(map(value =>
        value[0].concat(value[1])
      ));
    } else {
      return geoAddressData;
    }
  }

  initMap(inputMap: Map) {
    // return if the layer already exists
    if (inputMap.getLayer('route')) {
      return;
    }

    // add empty source
    inputMap.addSource('routeSource', {
      'type': 'geojson',
      'data': {
        'type': 'FeatureCollection',
        'features': [],
      },
    });

    // add route layer
    inputMap.addLayer({
      'id': 'route',
      'type': 'line',
      'source': 'routeSource',
      'layout': {
        'line-join': 'round',
        'line-cap': 'round'
      },
      'paint': {
        'line-color': ['get', 'color'],
        'line-width': 4,
      }
    });
  }

  removeDirections(inputMap: Map) {
    if (!inputMap || !inputMap.getLayer('route')) {
      return;
    }

    // set routeSource to an empty collection
    (inputMap.getSource('routeSource') as GeoJSONSource).setData({
      'type': 'FeatureCollection',
      'features': [],
    });
  }

  drawPath(inputMap: Map, route: MobiPlaRoute) {
    if (!inputMap.isStyleLoaded) {
      return;
    }

    this.initMap(inputMap);

    const routeSource: FeatureCollection<Geometry> = {
      'type': 'FeatureCollection',
      'features': [],
    };

    for (let n = 0; n < route.segments.length; n++) {
      if (route.segments[n].geometry.coordinates == null) {
        continue;
      }

      const coordArray = new Array(route.segments[n].geometry.coordinates.length);

      for (let i = 0; i < route.segments[n].geometry.coordinates.length; i++) {
        coordArray[i] = new Array(
          route.segments[n].geometry.coordinates[i][1],
          route.segments[n].geometry.coordinates[i][0]);
      }

      // create the segment with the coordinates and the right color
      const newSegment: Feature = {
        'type': 'Feature',
        'properties': {
          'color': this.segmentColors[route.segments[n].properties.mobilityDevice],
        },
        'geometry': {
          'type': 'MultiLineString',
          'coordinates': coordArray,
        },
      };
      routeSource.features.push(newSegment);
    }

    (inputMap.getSource('routeSource') as GeoJSONSource).setData(routeSource);
  }

  drawMultipleDirections(inputMap: Map, coordinates: any[]) {
    if (!inputMap.isStyleLoaded) { return; }
    this.initMap(inputMap);

    const routeSource: FeatureCollection<Geometry> = {
      'type': 'FeatureCollection',
      'features': [],
    };

    const newSegment: Feature = {
      'type': 'Feature',
      'properties': {
        'color': '#c19a66',
      },
      'geometry': {
        'type': 'MultiLineString',
        'coordinates': coordinates,
      },
    };
    routeSource.features.push(newSegment);
    (inputMap.getSource('routeSource') as GeoJSONSource).setData(routeSource);
  }

  drawDirections(inputMap: Map, coordinates: OpenrouteCoordinates): Observable<number> {

    if (inputMap.isStyleLoaded) {
      return this._apiService.getDirections(coordinates).pipe(
        map(direction => {

          this.initMap(inputMap);

          const routeSource: FeatureCollection<Geometry> = {
            'type': 'FeatureCollection',
            'features': [
              {
                'type': 'Feature',
                'properties': {
                  'color': '#c19a66',
                },
                'geometry': {
                  'type': 'LineString',
                  'coordinates': direction.body.features[0].geometry.coordinates
                }
              }
            ]
          };

          (inputMap.getSource('routeSource') as GeoJSONSource).setData(routeSource);
          return +(direction.body.features[0].properties.summary.distance / 1000).toFixed(0);
        })
      );
    }

    return empty();
  }

  getDistanceDuration(coordinates: OpenrouteCoordinates): Observable<any> {
    return this._apiService.getDirections(coordinates);
  }

  getDistanceDurationMatrixbyCar(matrixCoordinates: OpenrouteMatrixCoordinates): Observable<OpenRouteMatrixResponse> {
    return this._apiService.getTimeDistanceMatrixCar(matrixCoordinates);
  }

  getDistanceDurationMatrixbyWalk(matrixCoordinates: OpenrouteMatrixCoordinates): Observable<OpenRouteMatrixResponse> {
    return this._apiService.getTimeDistanceMatrixWalk(matrixCoordinates);
  }

  getDistanceDurationMatrixbyCycle(matrixCoordinates: OpenrouteMatrixCoordinates): Observable<OpenRouteMatrixResponse> {
    return this._apiService.getTimeDistanceMatrixCycle(matrixCoordinates);
  }

  eraseDirections(inputMap: Map, markers?: Marker[]) {
    this.removeDirections(inputMap);

    if (markers) {
      markers.forEach(marker => {
        marker.remove();
      });
    }
  }

  fitBound(inputMap: Map, bounds: LngLat[] | LngLatBounds) {
    let bounds_x_min = Number.MAX_VALUE;
    let bounds_y_min = Number.MAX_VALUE;
    let bounds_x_max = Number.MIN_VALUE;
    let bounds_y_max = Number.MIN_VALUE;
    let boundRectangle: LngLatBoundsLike;

    if (bounds instanceof Array) {
      bounds.forEach(lnglat => {
        if (lnglat.lng < bounds_x_min) {
          bounds_x_min = lnglat.lng;
        }
        if (lnglat.lng > bounds_x_max) {
          bounds_x_max = lnglat.lng;
        }
        if (lnglat.lat < bounds_y_min) {
          bounds_y_min = lnglat.lat;
        }
        if (lnglat.lat > bounds_y_max) {
          bounds_y_max = lnglat.lat;
        }
      });
      boundRectangle = [[bounds_x_min, bounds_y_min], [bounds_x_max, bounds_y_max]];
    } else {
      boundRectangle = bounds;
    }

    inputMap.fitBounds(boundRectangle, {
      padding: 40,
      maxZoom: 12
    });
  }

  fitBoudRoute(inputMap: Map, route: MobiPlaRoute) {
    const routeBounds: LngLat[] = [];

    for (let n = 0; n < route.segments.length; n++) {
      if (route.segments[n].geometry.coordinates == null) {
        continue;
      }

      for (let i = 0; i < route.segments[n].geometry.coordinates.length; i++) {
        routeBounds.push(new LngLat(
          route.segments[n].geometry.coordinates[i][1],
          route.segments[n].geometry.coordinates[i][0]));
      }
    }

    this.fitBound(inputMap, routeBounds);
  }

  createMarker(lnglat: LngLat, popupText: string = ''): Marker {
    // create a HTML element for each feature
    const el = document.createElement('div');
    const icon = document.createElement('i');
    el.className = 'marker-new';
    // make a marker for each feature and add to the map
    return new Marker(el)
      .setLngLat(lnglat);
  }

  createMobilityMarker(option: MobilityOption, popupText: string = ''): Marker {
    // create a HTML element for each feature
    const el = document.createElement('div');
    const icon = document.createElement('i');
    if (option.leg.serviceId === 'stadtmobil') {
      el.className = 'stadtmobil-marker';
    } else if (option.leg.serviceId === 'vrn') {
      el.className = 'vrn-marker';
    }
    el.setAttribute('id', JSON.stringify(option));
    const lngLat: LngLat = new LngLat(option.leg.from.lon, option.leg.from.lat);

    return new Marker(el)
      .setLngLat(lngLat)
      .setPopup(new Popup({ offset: 25 }) // add popups
        .setHTML('<h3>' + popupText + '</h3>'));
  }

  changeMarkerIcon(selected: boolean, lnglat: LngLat, option: MobilityOption): Marker {
    // create a HTML element for each feature
    const el = document.createElement('div');
    const icon = document.createElement('i');
    if (selected) {
      el.className = option.leg.serviceId + '-marker-selected';
    } else {
      el.className = option.leg.serviceId + '-marker';
    }

    el.setAttribute('id', JSON.stringify(option));

    return new Marker(el)
      .setLngLat(lnglat);
  }

  addMarker(inputMap: Map, marker: Marker) {
    marker.addTo(inputMap);
  }

  toggleMarkerPopup(marker: Marker) {
    marker.togglePopup();
  }

  removeMarker(inputMap: Map, marker: Marker) {
    marker.remove();
  }

  removeAllMarker(inputMap: Map, marker: Marker[]) {
    marker.forEach(item => {
      this.removeMarker(inputMap, item);
    });
  }

  drawCircle(mapComponent: DynamicMapComponent, center: LngLat, radius: number) {
    const mapboxCircle = require('mapbox-gl-circle');

    const bound = new LngLat(center.lng, center.lat).toBounds(radius);
    mapComponent.lngLatBounds$.next(bound);

    if (mapComponent.mapBoxCircle) {
      try {
        mapComponent.mapBoxCircle.remove();
        mapComponent.mapBoxCircle = null;
      } catch (e) {
        mapComponent.mapBoxCircle = null;
      }
    }

    // the radis needs to be bigger than 0
    if (radius <= 0) {
      return;
    }

    const circle = new mapboxCircle(
      {
        lat: center.lat,
        lng: center.lng
      },
      radius,
      {
        editable: false,
        fillColor: '#629798'
      }
    );

    mapComponent.mapBoxCircle = circle;
    circle.addTo(mapComponent.map);
  }

  removeCircle(mapComponent: DynamicMapComponent) {
    if (mapComponent.mapBoxCircle && mapComponent.map) {
      mapComponent.mapBoxCircle.remove();
      mapComponent.mapBoxCircle = null;
    }
  }

  getStaticMap(lng: number, lat: number, zoom: number = 10, size: string = '300x300', marker?: [[string]], ): Observable<any> {
    // TODO mutliple marker
    const req = `pin-m(${lng},${lat})/${lng},${lat},${zoom}/${size}`;
    return this._apiService.getMapAsImage(req).pipe(
      map(response => this._domSanitizer.bypassSecurityTrustUrl(response))
    );
  }
}
