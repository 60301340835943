export class ChargingCharacteristic {
    maximumCurrent: number;
    maximumPower: number;
    maximumVoltage: number;
    minimumCurrent: number;
    powerType: PowerType;
}

export enum PowerType {
    'AC_1_PHASE',
    'AC_3_PHASE',
    'DC',
}

