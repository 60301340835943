import { MatSnackBar } from '@angular/material';
import { Injectable } from '@angular/core';
import { NotificationComponent } from './notification/notification.component';

@Injectable()
export class NotificationService {

    constructor(private snackBar: MatSnackBar) { }

    showSuccess(msg: string) {
        this.snackBar.openFromComponent(NotificationComponent, {
            duration: 6000,
            panelClass: 'success',
            verticalPosition: 'bottom',
            horizontalPosition: 'right',
            data: {
                message: msg,
                icon: 'checkmark4'
            }
        });
    }

    showWarning(msg: string) {
        this.snackBar.openFromComponent(NotificationComponent, {
            duration: 6000,
            panelClass: 'fail',
            verticalPosition: 'bottom',
            horizontalPosition: 'right',
            data: {
                message: msg,
                icon: 'warning2'
            }
        });
    }
}
