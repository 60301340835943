import { ArrayToNamePipe } from './array-to-name.pipe';
import { CommonModule } from '@angular/common';
import { IdToNamePipe } from './id-to-name.pipe';
import { MomentPipe } from './moment.pipe';
import { NgModule } from '@angular/core';
import { TimeElapsedPipe } from './time-elapsed.pipe';
import { HighlightPipe } from './highlight.pipe';
import { BlobToBase64Pipe } from './blob-to-base64.pipe';
import { SafePipe } from './safe.pipe';
import { TimeDifferencePipe } from './time-difference.pipe';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    IdToNamePipe,
    MomentPipe,
    ArrayToNamePipe,
    TimeElapsedPipe,
    HighlightPipe,
    BlobToBase64Pipe,
    SafePipe,
    TimeDifferencePipe
  ],
  exports: [
    IdToNamePipe,
    MomentPipe,
    ArrayToNamePipe,
    HighlightPipe,
    BlobToBase64Pipe,
    SafePipe,
    TimeDifferencePipe
  ],
  providers: [
    IdToNamePipe,
    MomentPipe,
    ArrayToNamePipe,
    TimeElapsedPipe,
    HighlightPipe,
    BlobToBase64Pipe,
    SafePipe,
    TimeDifferencePipe
  ]
})
export class PipesModule { }
