var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { DateAdapter } from '@angular/material';
import * as moment from 'moment';
/** Format in which Dates get send to the Server */
export var DATE_PICKER_OUTPUT_FORMAT = 'YYYY-MM-DD';
/** Creates an array and fills it with values. */
function range(length, valueFunction) {
    var valuesArray = Array(length);
    for (var i = 0; i < length; i++) {
        valuesArray[i] = valueFunction(i);
    }
    return valuesArray;
}
/** Adapts Moment.js Dates for use with Angular Material. */
var CustomDateAdapter = /** @class */ (function (_super) {
    __extends(CustomDateAdapter, _super);
    function CustomDateAdapter(dateLocale) {
        var _this = _super.call(this) || this;
        _this.setLocale(dateLocale);
        return _this;
    }
    CustomDateAdapter.prototype.setLocale = function (locale) {
        var _this = this;
        _super.prototype.setLocale.call(this, locale);
        var momentLocaleData = moment.localeData(locale);
        this._localeData = {
            firstDayOfWeek: momentLocaleData.firstDayOfWeek(),
            longMonths: momentLocaleData.months(),
            shortMonths: momentLocaleData.monthsShort(),
            dates: range(31, function (i) { return _this.createDate(2017, 0, i + 1).format('D'); }),
            longDaysOfWeek: momentLocaleData.weekdays(),
            shortDaysOfWeek: momentLocaleData.weekdaysShort(),
            narrowDaysOfWeek: momentLocaleData.weekdaysMin(),
        };
    };
    CustomDateAdapter.prototype.getYear = function (date) {
        return this.clone(date).year();
    };
    CustomDateAdapter.prototype.getMonth = function (date) {
        return this.clone(date).month();
    };
    CustomDateAdapter.prototype.getDate = function (date) {
        return this.clone(date).date();
    };
    CustomDateAdapter.prototype.getDayOfWeek = function (date) {
        return this.clone(date).day();
    };
    CustomDateAdapter.prototype.getMonthNames = function (style) {
        // Moment.js doesn't support narrow month names, so we just use short if narrow is requested.
        return style == 'long' ? this._localeData.longMonths : this._localeData.shortMonths;
    };
    CustomDateAdapter.prototype.getDateNames = function () {
        return this._localeData.dates;
    };
    CustomDateAdapter.prototype.getDayOfWeekNames = function (style) {
        if (style == 'long') {
            return this._localeData.longDaysOfWeek;
        }
        if (style == 'short') {
            return this._localeData.shortDaysOfWeek;
        }
        return this._localeData.narrowDaysOfWeek;
    };
    CustomDateAdapter.prototype.getYearName = function (date) {
        return this.clone(date).format('YYYY');
    };
    CustomDateAdapter.prototype.getFirstDayOfWeek = function () {
        return this._localeData.firstDayOfWeek;
    };
    CustomDateAdapter.prototype.getNumDaysInMonth = function (date) {
        return this.clone(date).daysInMonth();
    };
    CustomDateAdapter.prototype.clone = function (date) {
        return moment(date, DATE_PICKER_OUTPUT_FORMAT).clone().locale(this.locale);
    };
    CustomDateAdapter.prototype.createDate = function (year, month, date) {
        // Moment.js will create an invalid date if any of the components are out of bounds, but we
        // explicitly check each case so we can throw more descriptive errors.
        if (month < 0 || month > 11) {
            throw Error("Invalid month index \"" + month + "\". Month index has to be between 0 and 11.");
        }
        if (date < 1) {
            throw Error("Invalid date \"" + date + "\". Date has to be greater than 0.");
        }
        var result = moment.utc({ year: year, month: month, date: date }).locale(this.locale);
        // If the result isn't valid, the date must have been out of bounds for this month.
        if (!result.isValid()) {
            throw Error("Invalid date \"" + date + "\" for month with index \"" + month + "\".");
        }
        return result;
    };
    CustomDateAdapter.prototype.today = function () {
        return moment.utc().locale(this.locale);
    };
    CustomDateAdapter.prototype.parse = function (value, parseFormat) {
        if (value && typeof value == 'string') {
            return moment.utc(value, parseFormat, this.locale);
        }
        return value ? moment.utc(value).locale(this.locale) : null;
    };
    CustomDateAdapter.prototype.format = function (date, displayFormat) {
        date = this.clone(date);
        if (!this.isValid(date)) {
            throw Error('MomentDateAdapter: Cannot format invalid date.');
        }
        return date.format(displayFormat);
    };
    CustomDateAdapter.prototype.addCalendarYears = function (date, years) {
        return this.clone(date).add({ years: years });
    };
    CustomDateAdapter.prototype.addCalendarMonths = function (date, months) {
        return this.clone(date).add({ months: months });
    };
    CustomDateAdapter.prototype.addCalendarDays = function (date, days) {
        return this.clone(date).add({ days: days });
    };
    CustomDateAdapter.prototype.toIso8601 = function (date) {
        return this.clone(date).format();
    };
    /**
     * Returns the given value if given a valid Moment or null. Deserializes valid ISO 8601 strings
     * (https://www.ietf.org/rfc/rfc3339.txt) and valid Date objects into valid Moments and empty
     * string into null. Returns an invalid date for all other values.
     */
    CustomDateAdapter.prototype.deserialize = function (value) {
        var date;
        if (value instanceof Date) {
            date = moment.utc(value);
        }
        if (typeof value === 'string') {
            if (!value) {
                return null;
            }
            date = moment.utc(value, moment.ISO_8601).locale(this.locale);
        }
        if (date && this.isValid(date)) {
            return date;
        }
        return _super.prototype.deserialize.call(this, value);
    };
    CustomDateAdapter.prototype.isDateInstance = function (obj) {
        // console.log('isDateInstance: ', obj)
        return true;
    };
    CustomDateAdapter.prototype.isValid = function (date) {
        return this.clone(date).isValid();
    };
    CustomDateAdapter.prototype.invalid = function () {
        return moment.invalid();
    };
    return CustomDateAdapter;
}(DateAdapter));
export { CustomDateAdapter };
