import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicMapComponent } from './dynamic-map/dynamic-map.component';
import { MapService } from './map.service';
import { MapAPIService } from './map.api.service';
import { StaticMapComponent } from './static-map/static-map.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [DynamicMapComponent, StaticMapComponent],
  exports: [
    DynamicMapComponent,
    StaticMapComponent,
  ],
  providers: [
    MapService,
    MapAPIService
  ]
})
export class MapModule { }
