import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Observable, empty, fromEvent } from 'rxjs';
import { map, flatMap, catchError } from 'rxjs/operators';

@Pipe({
  name: 'blobToBase64'
})
export class BlobToBase64Pipe implements PipeTransform {

  constructor(
    private _http: HttpClient,
    private _sanitizer: DomSanitizer
  ) { }

  transform(url: string): Observable<SafeUrl> {
    // add token to http header
    const httpHeaders = new HttpHeaders({
      'UbstackUserAuthorization': 'Bearer ' + localStorage.getItem('token')
    });
    return this._http.get(url, { headers: httpHeaders, responseType: 'blob' }).pipe(
      flatMap(response => this.createImageFromBlob(response)),
      map(src => this._sanitizer.bypassSecurityTrustUrl(src)),
      catchError(error => {
        console.log(console.error(error));
        return empty();
      })
    );
  }

  // convert Blob to base64 streing
  createImageFromBlob(image: Blob): Observable<string> {
    const reader = new FileReader();
    // create observable of onloaded event
    const readerObs = fromEvent(reader, 'load');
    reader.readAsDataURL(image);
    return readerObs.pipe(
      map(() => <string>reader.result)
    );
  }

}
